//Concertar bug admin lte não aparecer side bar no mobile depois de ter setado como menu completo
window.addEventListener("resize", function () {
  let tamanho_janela_widith = window.innerWidth;

  if (tamanho_janela_widith <= 766) {
    $("body").addClass("sidebar-collapse");

    $(".content-wrapper").css("margin-left", "4.6rem !important;");

    $(".navbar-nav").on("click touchstart", function () {
      $("body").toggleClass("sidebar-collapse");
      if ($("body").hasClass("sidebar-collapse")) {
        $(".content-wrapper").css("margin-left", "0rem !important;");
        $(".titulo-categoria").style.visibility = "hidden";
      } else {
        $(".content-wrapper").css("margin-left", "4.6rem !important;");
        $(".titulo-categoria").style.visibility = "";
      }
      //$('.content-wrapper').css('margin-left', '0px');
    });
  }
});

//
$(".logo").on("click", function () {
  location.pathname = "/";
})

$("body").on("click", function (event) {
  //Se clicar em qualquer lugar sem ser na side bar e ela tiver expandida, deixa ela minimizada
  if (!event.target.classList.contains("sidebar")) {
    if (!$("body").hasClass("sidebar-collapse")) {
      $("body").addClass("sidebar-collapse");
    }
  }
});

$(".user-menu a.nav-link.dropdown-toggle").html(
  '<i class="fa fa-times" aria-hidden="true"></i>'
);

if ($("#cliente").has("option").length === 0) {
  $("#modal_sem_clientes_cadastrados").modal("show");
}

const modal_tutorial = $('#modal_video_boas_vindas_tutorial');
$('.btn_ir_arqc_tutorial').on('click', function(){
  modal_tutorial.modal('hide');
})