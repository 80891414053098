require("./bootstrap");
var base_url = window.location.host;
/* import Vue from "vue";
import App from "./App.vue"; */

/* import VCalendar from "v-calendar";
Vue.use(VCalendar); */

/* Vue.config.productionTip = false; */

/* import Modal from "./components/Modal.vue";
import CorpoForm from "./components/CorpoForm.vue";
import Novo from "./components/Novo.vue";
import InputForm from "./components/InputForm.vue";
import ModalSucesso from "./components/ModalSucesso.vue";
import Teste from "./components/Teste.vue";
import CardProduto from "./components/CardProduto.vue";
import InserirImagem from "./components/InserirImagem.vue";
import MultiSelectTeste from "./components/MultiSelectTeste.vue";
import Multiselect from "vue-multiselect"; */

/* import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd"; */

/* import ModalPdf from "./components/ModalPdf.vue"; */

/* Vue.component("ModalPdf", ModalPdf);
Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);

Vue.component("multiselect", Multiselect);
Vue.component("MultiSelectTeste", MultiSelectTeste);
Vue.component("Modal", Modal);
Vue.component("Novo", Novo);
Vue.component("CorpoForm", CorpoForm);
Vue.component("InputForm", InputForm);
Vue.component("ModalSucesso", ModalSucesso);
Vue.component("Teste", Teste);
Vue.component("CardProduto", CardProduto);
Vue.component("InserirImagem", InserirImagem); */

/* import store from "./vuex/vuex";
import router from "./router/router"; */

/* new Vue({
  store,
  router,
  components: {
    App,
  },
}).$mount("#app"); */
